































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import ValuesInput from "./ValuesInput.vue";
import ValuesHistory from "./ValuesHistory.vue";
import XButton from "@/components/SimpleButton.vue";
import XSlider from "@/components/SimpleSlider.vue";
import MassValuesInput from "./MassValuesInput.vue";
import {AccountingPointIndications} from "@/models/accounting-point";

@Component({
  components: {
    Page,
    XTabs,
    ValuesInput,
    ValuesHistory,
    XButton,
    XSlider,
    MassValuesInput,
  },
  computed: {
    ...mapState({
      appEnvironment: "appEnvironment",
      currentContract: "contract",
      accountingPointsPU: "accountingPoints",
    }),
    ...mapGetters({
      organizationType: "organization/organizationType",
    }),
    accountingPoints() {
      if (this.accountingPointsPU.accountingPointsPU != undefined) {
        const accountingPointsPU = this.accountingPointsPU.accountingPointsPU.map((item) => {
          return item as AccountingPointIndications;
        });
        return [...new Map(accountingPointsPU.map(item => [item.счетчикид, item])).values()];
      }
      return [];
    },
  },
})
class CountersValues extends Vue {
  [x: string]: any;

  titles = ["Ввод показаний", "История показаний"];
  components = [ValuesInput, ValuesHistory];

  showMassValuesForm = false;

  public get useValuesInput(): boolean {
    const { appEnvironment } = this;

    return !!(appEnvironment.constants && appEnvironment.constants["ЛКЮЛПОКАЗ"]);
  }

  disabled() {
    const noBlockedAccountingPoints = this.accountingPoints.filter((item) => item.запретвводапоказаний == 0).length
    return noBlockedAccountingPoints == 0 || (this.organizationType == 'Физ лицо' && this.currentContract.contract['эдолк'] == 0)
  }
}

export default CountersValues;
