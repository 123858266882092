











































import {Component, Mixins, Prop, Vue} from "vue-property-decorator";
import { AccountingPointIndications } from "@/models/accounting-point";
import SimpleCard from "../SimpleCard.vue";
import NewValueForm from "./CountersNewValueForm.vue";
import ContractIcon from "@/components/ContractIcon.vue";
import SimpleCardWithList from "@/components/SimpleCardWithList.vue";
import {mapGetters, mapState} from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";

@Component({
  components: {
    SimpleCard,
    ContractIcon,
    NewValueForm,
    SimpleCardWithList
  },
  computed: {
    ...mapState({ currentContract: "contract" }),
    ...mapGetters({
      accountingPoints: "accountingPoints/allPoints",
      organizationType: "organization/organizationType",
    })
  },
})
class EquipmentListCard extends Mixins(AppApiMixin) {
  [x: string]: any;

  @Prop({required: true}) readonly equipment!: AccountingPointIndications;

  get disabled() {
    return this.equipment.запретвводапоказаний != 0 || (this.currentContract.contract['эдолк'] == 0 && this.currentContract.contract['эдо'] == 0);
  }

  equipmentPointAddress(id) {
    let address =  this.accountingPoints.filter((val) => val.номер == id)
    return (address.length != 0) ? address[0].адрес : "";
  }

  get counterData() {
    const {типсчетчика, заводскойномер, услуга, тип, тарифность, коэфтр} = this.equipment;

    return [
      {title: 'марка', value: типсчетчика},
      {title: 'заводской номер', value: заводскойномер},
      {title: 'услуга', value: услуга},
      {title: 'тип', value: тип},
      {title: 'тарифность', value: тарифность},
      {title: 'коэф.тр', value: коэфтр},
    ]
  }
}

export default EquipmentListCard;
