

















import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import EquipmentListCard from "@/components/for-counters-values/EquipmentListCard.vue";
import {AccountingPointIndication, AccountingPointIndications } from "@/models/accounting-point";

@Component({
  components: {
    EquipmentListCard,
  },
  computed: {
    ...mapState({
      accountingPointsPU: "accountingPoints",
    }),
    ...mapState({ currentContract: "contract" }),
    ...mapGetters({
      contractId: "contract/id",
      organizationType: "organization/organizationType",
    }),
    accountingPoints() {
      if (this.accountingPointsPU.accountingPointsPU != undefined) {
        const accountingPointsPU = this.accountingPointsPU.accountingPointsPU.map((item) => {
          return item as AccountingPointIndications;
        });
        return [...new Map(accountingPointsPU.map(item => [item.счетчикид, item])).values()];
      }
      return [];
    }
  },
})
class ValuesInput extends Mixins(AppApiMixin) {
  [x: string]: any;

  created() {
    this.fetchAccountingPointsPU(this.contractId);
  }

  get showBlockDisabled() {
    const noBlockedAccountingPoints = this.accountingPoints.filter((item) => item.запретвводапоказаний == 0).length
    return noBlockedAccountingPoints > 0
      && this.currentContract.contract['эдолк'] == 0
      && this.currentContract.contract['эдо'] == 0
  }
}

export default ValuesInput;

