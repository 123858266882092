import {required, checkbox} from "./field-rules";

export default [
  {
    name: "allowDataHandling",
    attrs: {
      type: "checkbox",
      label: "Я принимаю Соглашение об использовании электронных сервисов",
      required: true
    },
    rules: [required, checkbox]
  },
  {
    name: "allowDataHandling2",
    attrs: {
      type: "checkbox",
      label: "Я принимаю Соглашение о признании электронной подписи равнозначной собственноручной",
      required: true
    },
    rules: [required, checkbox]
  },
  {
    name: "allowDataHandling3",
    attrs: {
      type: "checkbox",
      label: "Я принимаю Соглашение об обработке персональных данных",
      required: true
    },
    rules: [required, checkbox]
  }
]
