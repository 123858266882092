













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class Button extends Vue {
  @Prop({ default: "primary" }) readonly color!: string;
  @Prop({ default: false }) readonly disabled?: boolean;
}

export default Button;
