





















































import {Component, Mixins, Watch} from "vue-property-decorator";
import {mapGetters} from "vuex";
import Page from "@/components/for-page-grid/Page.vue";
import XButton from "@/components/SimpleButton.vue";
import WelcomeCard from "@/components/for-my-contracts/WelcomeCard.vue";
import ContractTable from "@/components/for-my-contracts/ContractTable.vue";
import XSlider from "@/components/SimpleSlider.vue";
import ContractAddingForm
  from "@/components/for-my-contracts/ContractAddingForm.vue";
import ContractRemovingDialog
  from "@/components/for-my-contracts/ContractRemovingDialog.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import XDropdownWithRadioButtons
  from "@/components/hoc/DropdownWithRadioButtons.vue";

@Component({
  components: {
    XDropdownWithRadioButtons,
    Page,
    XButton,
    WelcomeCard,
    ContractTable,
    XSlider,
    ContractAddingForm,
    ContractRemovingDialog,
  },
  computed: {
    ...mapGetters("contract", { useContract: "useContract" }),
    ...mapGetters("contracts", {
      sortedContractsByOrganization: "sortedContractsByOrganization",
      balanceListByContracts: "balanceList",
    }),
  },
})
class MyContracts extends Mixins(AppApiMixin) {
  dropdownGroupCssClasses = [
    "tabs__dropdown-group",
    "box",
    "box_shadow_initial",
    "px-6",
    "py-2",
    "rounded-lg",
    "white",
  ];

  showContractAddingForm = false;

  showContractRemovingDialog = false;
  contractIdForRemoving = -1;

  contractItems = [];

  public mounted() {
    this.prepareContractItems();
    this.enterApp(true);
  }

  public closeContractRemovingDialog() {
    this.showContractRemovingDialog = false;
    this.contractIdForRemoving = -1;
  }

  prepareContractItems() {
    let contractItems = [];
    if (this.sortedContractsByOrganization === null) {
      return;
    }
    this.sortedContractsByOrganization.forEach((contractItem) => {
      let contractList = [];
      contractItem.contractList.forEach((contractListItem) => {
        contractList.push({ ...contractListItem });
      });
      contractItems.push({...contractItem, contractList: contractList });
    });
    this.contractItems = contractItems
  }

  @Watch("sortedContractsByOrganization")
  sortedContractsByOrganizationChanged() {
    this.prepareContractItems();
  }
}

export default MyContracts;
